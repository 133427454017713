//_____________________________________________________________________**_LIVE
//_____________________________________________________________________**_AWS SERVER API
const API = "https://api.jode.tech/api/v1/";
//_____________________________________________________________________**_AWS SOCKET URL
export const SOCKET_URL = "https://api.jode.tech";
//_____________________________________________________________________**_DOWNLOAD QR LIVE CODE URL
export const QR = "https://app.jod-e.com/";
//_____________________________________________________________________**_RAZORPAY LIVE KEY
export const RAZORPAY_KEY = "rzp_live_pfczxfzFuUDH2K";
//_____________________________________________________________________**_IMAGE AWS CLOUDFRONT PARTH
// export const IMAGE_URL = "";
// export const IMAGE_URL = "https://media.jodeapi.xyz/";
// export const IMAGE_URL = "https://d21ewolg7aagb0.cloudfront.net/"
// export const IMAGE_URL = "https://jode.s3.ap-south-1.amazonaws.com/"
export const IMAGE_URL = "https://jode.blr1.digitaloceanspaces.com/"
//_____________________________________________________________________**_ICON VERSION
export const ICON_V = "v1";

//_____________________________________________________________________**_TESTING
//_____________________________________________________________________**_TESTING SERVER
// const API = "https://jod-e-backend.herokuapp.com/api/v1/";
//_____________________________________________________________________**_LIVE SOCKET URL
// export const SOCKET_URL = "https://jod-e-backend.herokuapp.com";

//_____________________________________________________________________**_LOCAL
//_____________________________________________________________________**_LOCAL SERVER
// const API = "http://localhost:5001/api/v1/";
//_____________________________________________________________________**_LOCAL SOCKET URL
// export const SOCKET_URL = "http://localhost:5001";
//_____________________________________________________________________**_RAZORPAY TESTING KEY
// export const RAZORPAY_KEY = "rzp_test_zrx8rP6wxZLf43";

export default API;
